;((googleAnalyticsTrackingId) => {
  const script = (() => {
    const [firstScript] = document.getElementsByTagName('script')
    const element = document.createElement('script')
    element.async = true
    element.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsTrackingId}`
    firstScript.parentNode.insertBefore(element, firstScript)
    return element
  })()

  script.addEventListener('load', () => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', googleAnalyticsTrackingId)

    gtag('event', 'page_view', {
      page_location: `${document.location.origin}${document.location.pathname}`,
      page_path: document.location.pathname,
      page_title: document.head.title,
    })

    if (window.performance) {
      gtag('event', 'PageLoad', {
        event_category: 'timing',
        event_label: 'load',
        value: Math.round(window.performance.now()),
      })
    }

    document.querySelectorAll('[data-track-click]').forEach((node) => {
      const category = node.getAttribute('data-category') || node.tagName
      const label = node.getAttribute('data-label') || node.title || node.textContent
      const value = Math.round(+node.getAttribute('data-track-click') || 1)

      node.addEventListener('click', () => {
        gtag('event', 'click', {
          event_category: category,
          event_label: label,
          value: value,
        })
      })
    })
  })
})('UA-68494821-1')
